/// <reference types="@angular/localize" />

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { enableElfProdMode } from '@ngneat/elf';
import {
    browserTracingIntegration,
    init,
    makeBrowserOfflineTransport,
    makeFetchTransport,
    reportingObserverIntegration,
    type BrowserOptions,
} from '@sentry/angular';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

registerLocaleData(localeDe, localeDeExtra);

if (environment.sentryDsn !== '' && environment.sentryEnvironment !== '') {
    const integrations: BrowserOptions['integrations'] = [reportingObserverIntegration()];

    if (Array.isArray(environment.sentryTracingOrigins) && environment.sentryTracingOrigins.length > 0) {
        integrations.push(browserTracingIntegration());
    }

    init({
        denyUrls: [
            /https:\/\/survey\.lamapoll\.de\/lp\/js\/lp\.ext\.loader\.js/,
        ],
        dsn: environment.sentryDsn,
        environment: environment.sentryEnvironment,
        transport: makeBrowserOfflineTransport(makeFetchTransport),
        integrations,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment.sentryTracesSampleRate,
        tracePropagationTargets: environment.sentryTracingOrigins,
    });
}

if (environment.production) {
    enableProdMode();
    enableElfProdMode();
}

document.addEventListener('DOMContentLoaded', (): void => {
    bootstrapApplication(AppComponent, appConfig)
        .catch((err: Error): void => {
            // eslint-disable-next-line no-console
            console.error(err);
        });
});
