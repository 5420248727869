import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { type ApplicationConfig, ErrorHandler, importProvidersFrom, inject, LOCALE_ID, provideAppInitializer } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withEnabledBlockingInitialNavigation, withPreloading } from '@angular/router';
import { provideNodeBigError } from '@big-direkt/error-handling';
import {
    provideFormViewAddress,
    provideFormViewAlert,
    provideFormViewCheckboxes,
    provideFormViewCheckbox,
    provideFormViewComposite,
    provideFormViewContactFormSearch,
    provideFormViewContainer,
    provideFormViewCurrencyInput,
    provideFormViewDateList,
    provideFormViewDate,
    provideFormViewDateRange,
    provideFormViewDateTime,
    provideFormViewElementsHolder,
    provideFormViewEmbeddedForm,
    provideFormViewFieldGroup,
    provideFormViewFieldSet,
    provideFormViewFileUpload,
    provideFormViewForm,
    provideFormViewFormRelation,
    provideFormViewHidden,
    provideFormViewHin,
    provideFormViewIFrame,
    provideFormViewItemCollection,
    provideFormViewItem,
    provideFormViewLabel,
    provideFormViewMarkup,
    provideFormViewMobileAppInfo,
    provideFormViewMultipleItem,
    provideFormViewMultiple,
    provideFormViewNavigationButton,
    provideFormViewNumberField,
    provideFormViewOneTimeToken,
    provideFormViewPage,
    provideFormViewPasswordConfirm,
    provideFormViewPassword,
    provideFormViewPdfDownload,
    provideFormViewPreview,
    provideFormViewPrivacyPolicy,
    provideFormViewProgressBar,
    provideFormViewQuantitySelect,
    provideFormViewRadio,
    provideFormViewRadios,
    provideFormViewSearchableSelect,
    provideFormViewSelect,
    provideFormViewTextarea,
    provideFormViewTextfield,
    provideFormViewTime,
    provideFormViewTimeRange,
} from '@big-direkt/form/view';
import { provideNodeBigAccordion, provideNodeBigAccordionItem, provideNodeBigArticle, provideNodeBigForm, provideNodeBigLegal } from '@big-direkt/nodes';
import { provideAccordionParagraph } from '@big-direkt/paragraphs/accordion';
import { provideButtonParagraph } from '@big-direkt/paragraphs/button';
import { provideC2aBannerParagraph } from '@big-direkt/paragraphs/c2a-banner';
import { provideCardGroupParagraph } from '@big-direkt/paragraphs/card-group';
import { provideHeadingParagraph } from '@big-direkt/paragraphs/heading';
import { provideImageParagraph } from '@big-direkt/paragraphs/image';
import { provideInstructionParagraph } from '@big-direkt/paragraphs/instruction';
import { provideLinkListParagraph } from '@big-direkt/paragraphs/link-list';
import { provideListParagraph } from '@big-direkt/paragraphs/list';
import { provideQuickLinkListParagraph } from '@big-direkt/paragraphs/quick-link-list';
import { provideSearchableLinkListParagraph } from '@big-direkt/paragraphs/searchable-link-list';
import { provideTableParagraph } from '@big-direkt/paragraphs/table';
import { provideTextParagraph } from '@big-direkt/paragraphs/text';
import { provideVideoGroupParagraph } from '@big-direkt/paragraphs/video-group';
import { provideWebformParagraph } from '@big-direkt/paragraphs/webform';
import { RestApiClientModule } from '@big-direkt/rest-api-client';
import { initialState as MobileAppInitialState, MobileAppRepository, mobileAppStore } from '@big-direkt/state/mobile-app';
import { uiStore } from '@big-direkt/state/ui';
import { UserModule, userStore } from '@big-direkt/state/user';
import {
    CustomErrorHandlerService,
    DarkModeService,
    EnvironmentService,
    FeatureFlagsService,
    LogSentryInterceptor,
    STATIC_ENVIRONMENT,
    darkmodeStore,
    featureFlagsStore,
    responseInterceptor,
} from '@big-direkt/utils/environment';
import { provideTranslocoRoot } from '@big-direkt/utils/i18n';
import { localStorageStrategy, persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { NgOverlayContainerModule } from 'ng-overlay-container';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { MobileAppVersionHeaderInterceptor } from './mobile-app-version-header-interceptor';

registerLocaleData(localeDE);

MobileAppInitialState.mobileApp.isEmbeddedInMobileApp = true;

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: LOCALE_ID, useValue: 'de-DE' },
        provideAppInitializer(async () => {
            const initializerFn = (
                (environmentService: EnvironmentService, featureFlagService: FeatureFlagsService, darkmodeService: DarkModeService): (() => Promise<void>) =>
                    async () => {
                        await firstValueFrom(environmentService.load());
                        await firstValueFrom(featureFlagService.load());
                        darkmodeService.handleDarkMode(true);
                    }
            )(inject(EnvironmentService), inject(FeatureFlagsService), inject(DarkModeService));

            return initializerFn();
        }),
        { provide: STATIC_ENVIRONMENT, useValue: environment },

        provideAnimations(),
        provideHttpClient(withInterceptors([responseInterceptor]), withFetch(), withInterceptorsFromDi()),
        provideTranslocoRoot(),
        provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withPreloading(PreloadAllModules)),

        importProvidersFrom(RestApiClientModule),
        importProvidersFrom(NgOverlayContainerModule),
        importProvidersFrom(UserModule),

        {
            provide: 'persistStorage',
            useValue: persistState(featureFlagsStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(darkmodeStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(userStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(mobileAppStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(uiStore, {
                key: 'app',
                storage: sessionStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MobileAppVersionHeaderInterceptor,
            multi: true,
            deps: [MobileAppRepository],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LogSentryInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandlerService,
        },

        // Nodes
        provideNodeBigAccordion(),
        provideNodeBigAccordionItem(),
        provideNodeBigArticle(),
        provideNodeBigError(),
        provideNodeBigForm(),
        provideNodeBigLegal(),

        // Paragraphs
        provideAccordionParagraph(),
        provideButtonParagraph(),
        provideC2aBannerParagraph(),
        provideCardGroupParagraph(),
        provideHeadingParagraph(),
        provideImageParagraph(),
        provideInstructionParagraph(),
        provideLinkListParagraph(),
        provideListParagraph(),
        provideQuickLinkListParagraph(),
        provideSearchableLinkListParagraph(),
        provideTableParagraph(),
        provideTextParagraph(),
        provideVideoGroupParagraph(),
        provideWebformParagraph(),

        // Forms
        // Fields
        provideFormViewAddress(),
        provideFormViewAlert(),
        provideFormViewCheckbox(),
        provideFormViewCheckboxes(),
        provideFormViewContactFormSearch(),
        provideFormViewCurrencyInput(),
        provideFormViewDate(),
        provideFormViewDateRange(),
        provideFormViewDateList(),
        provideFormViewDateTime(),
        provideFormViewFileUpload(),
        provideFormViewHidden(),
        provideFormViewHin(),
        provideFormViewIFrame(),
        provideFormViewMarkup(),
        provideFormViewMobileAppInfo(),
        provideFormViewNavigationButton(),
        provideFormViewNumberField(),
        provideFormViewOneTimeToken(),
        provideFormViewPassword(),
        provideFormViewPasswordConfirm(),
        provideFormViewPrivacyPolicy(),
        provideFormViewQuantitySelect(),
        provideFormViewRadio(),
        provideFormViewRadios(),
        provideFormViewSearchableSelect(),
        provideFormViewSelect(),
        provideFormViewTextarea(),
        provideFormViewTextfield(),
        provideFormViewTime(),
        provideFormViewTimeRange(),

        // Preview
        provideFormViewPreview(),

        // Structures
        provideFormViewComposite(),
        provideFormViewContainer(),
        provideFormViewElementsHolder(),
        provideFormViewEmbeddedForm(),
        provideFormViewFieldGroup(),
        provideFormViewFieldSet(),
        provideFormViewForm(),
        provideFormViewFormRelation(),
        provideFormViewItem(),
        provideFormViewItemCollection(),
        provideFormViewLabel(),
        provideFormViewMultiple(),
        provideFormViewMultipleItem(),
        provideFormViewPage(),
        provideFormViewPdfDownload(),
        provideFormViewProgressBar(),
    ],
};
